import logo from "../../assets/images/homepage/s_logo.svg";
import hipaa from "../../assets/images/common/footer/hipaa.svg";
import iso27001 from "../../assets/images/common/footer/iso-27001.svg";
import API_CONFIG from "../../config";
import { useGlobalContext } from "../../globalContext";
import { Link } from "react-router-dom";

const Footer = ({ data }) => {
  const { showLoader } = useGlobalContext();
  const {
    global: { logo },
    footer: {
      badges,
      copyright_text,
      footer_bg,
      footer_bg_mobile,
      legal_page_links,
      menu_links,
      menu_title,
      services_links,
      services_title,
    },
  } = data;
  return (
    !showLoader && (
      <footer
        className="py-24 bg-purple-9 bg-contain md:bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${API_CONFIG.baseURL}${footer_bg.data?.attributes?.url})`,
        }}
      >
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-2 gap-4 mb-56 md:mb-48">
            <div className="col-span-2 md:col-span-1">
              <p className="b4 text-purple-5 mb-4">{menu_title}</p>
              <div className="flex flex-wrap gap-4">
                {menu_links.map(({ id, Text, Link: link }) => {
                  return (
                    <Link to={link} key={id}>
                      <div className="text-grey-4 rounded-3xl border border-solid border-grey-7 py-2 px-6">
                        {Text}
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <p className="b4 text-purple-5 mb-4">{services_title}</p>
              <div className="flex flex-wrap gap-4">
                {services_links.map(({ id, Text, Link: link }) => {
                  return (
                    <Link to={link} key={id}>
                      <div className="text-grey-4 rounded-3xl border border-solid border-grey-7 py-2 px-6 whitespace-nowrap">
                        {Text}
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
          <img
            src={`${API_CONFIG.baseURL}${logo.data.attributes.url}`}
            alt="Logo"
            className="w-full mb-6"
          />
          {badges.data ? (
            <div className="mb-16 flex gap-8 md:gap-16 justify-center">
              {badges.data.map((badge) => {
                return (
                  <img
                    key={badge.id}
                    src={`${API_CONFIG.baseURL}${badge.attributes.url}`}
                    alt={badge.attributes.alternativeText}
                    className="mb-6"
                  />
                );
              })}
            </div>
          ) : (
            ""
          )}
          <hr className="border-t border-purple-6 mb-8"></hr>
          <div className="flex justify-between gap-3 flex-wrap">
            <p className="b4 text-grey-4">{copyright_text}</p>
            <div className="flex gap-10">
              {legal_page_links.map(({ id, Text, Link: link }) => {
                return (
                  <Link to={link} key={id}>
                    <div className="b4 text-grey-4">{Text}</div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </footer>
    )
  );
};

export default Footer;
